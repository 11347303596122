import React from 'react';
import cx from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import {
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Typography,
  Theme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MessageId } from '../../../i18n';

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  item: {},
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const items: {
  title: MessageId;
  description: MessageId;
}[] = [
  {
    title: 'website.join.faq.cancel.title',
    description: 'website.join.faq.cancel.description',
  },
  {
    title: 'website.join.faq.charge.title',
    description: 'website.join.faq.charge.description',
  },
  {
    title: 'website.join.faq.disclosed.title',
    description: 'website.join.faq.disclosed.description',
  },
  {
    title: 'website.join.faq.trial.title',
    description: 'website.join.faq.trial.description',
  },
  {
    title: 'website.join.faq.refund.title',
    description: 'website.join.faq.refund.description',
  },
];

function Faq(): JSX.Element {
  const intl = useIntl();
  const classes = useStyles();
  const [expandedIndex, setExpandedIndex] = React.useState(-1);

  const handleExpandClick = React.useCallback(
    index => {
      if (expandedIndex === index) {
        setExpandedIndex(-1);
      } else {
        setExpandedIndex(index);
      }
    },
    [expandedIndex],
  );

  return (
    <div className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        <FormattedMessage id="website.join.faq" />
      </Typography>
      {items.map((item, index) => (
        <div key={item.title} className={classes.item}>
          <CardHeader
            title={intl.formatMessage({ id: item.title })}
            titleTypographyProps={{ variant: 'body1' }}
            action={
              <IconButton
                className={cx(classes.expand, {
                  [classes.expandOpen]: expandedIndex === index,
                })}
                onClick={() => handleExpandClick(index)}
                aria-expanded={expandedIndex === index}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            }
          />
          <Collapse in={expandedIndex === index} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography variant="body2">
                <FormattedMessage id={item.description} />
              </Typography>
            </CardContent>
          </Collapse>
        </div>
      ))}
    </div>
  );
}

export default Faq;

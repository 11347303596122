import React from 'react';
import { Redirect } from 'react-router-dom';

// TODO
function TagList() {
  return <Redirect to="/" />;
  // const classes = useStyles();
  // const { loading, data } = useQuery(GetUserInfo, {
  //   variables: { userId: userUrlSlug },
  // });

  // if (loading) {
  //   return (
  //     <div className={classes.container}>
  //       <Typography variant="h4" component="h1" className={classes.title}>
  //         所有標籤
  //       </Typography>
  //       <div className={classes.loadingContainer}>
  //         <CenterLoading />
  //       </div>
  //     </div>
  //   );
  // }

  // return (
  //   <Container maxWidth="sm" className={classes.container}>
  //     <TagListHeader data={data} />
  //     <Typography variant="h4" component="h1" className={classes.title}>
  //       所有標籤
  //     </Typography>
  //     <ul className={classes.list}>
  //       {data.userInfo.tags.map(t => (
  //         <li key={t.tag}>
  //           <MuiLink
  //             variant="h4"
  //             component={Link}
  //             to={`/tags/${encodeURIComponent(t.tag)}`}
  //           >
  //             #{t.tag}
  //           </MuiLink>
  //         </li>
  //       ))}
  //     </ul>
  //   </Container>
  // );
}

export default TagList;

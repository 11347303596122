import { makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Plan } from '../../../entities/membership';
import { formatMoney } from '../../Donation/Join/util';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  amount: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  interval: {
    textAlign: 'center',
    display: 'block',
  },
}));

function PlanItem({ plan }: PlanItemProps): JSX.Element {
  const classes = useStyles();

  return (
    <Paper variant="outlined" className={classes.container}>
      <Typography variant="body2" className={classes.amount}>
        {formatMoney(plan.price.money)}
      </Typography>
      {plan.price.interval ? (
        <Typography variant="caption" className={classes.interval}>
          <FormattedMessage
            id={
              plan.price.interval.unit === 'm'
                ? 'donation.monthly'
                : 'donation.yearly'
            }
          />
        </Typography>
      ) : null}
    </Paper>
  );
}

type PlanItemProps = {
  plan: Plan;
};

export default PlanItem;

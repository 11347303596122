import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Theme, Paper } from '@material-ui/core';
import EpisodeInfoRow from '../../components/EpisodeInfoRow';
import TagRow from '../../components/TagRow';
import { useLazyImageSrc } from '../../components/LazyImage';
import Html from '../../components/Html';
import { imageproxy } from '../../utils';
import { Episode } from '../../entities/episode';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      height: 240,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  media: {
    width: 240,
    height: 240,
  },
  content: {
    flex: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  info: {
    flex: 1,
  },
  truncateText: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  title: {
    fontWeight: 'bold',
  },
  description: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
  },
  infoRow: {
    padding: theme.spacing(1),
  },
  actions: {
    paddingBottom: 0,
  },
}));

function EpisodeItem({ episode }: EpisodeItemProps): JSX.Element {
  const classes = useStyles();
  const { id, title, description, show } = episode;

  const imageUrl = episode.imageUrl || show?.avatar || '#';

  const { ref, src } = useLazyImageSrc({
    src: imageproxy(imageUrl, 500),
    placeholder: imageproxy(imageUrl, 50),
  });

  return (
    <Link to={`/episodes/${id}`}>
      <Paper className={classes.root} variant="outlined">
        <img className={classes.media} ref={ref} src={src} />
        <div className={classes.content}>
          <div className={classes.info}>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              className={cx(classes.truncateText, classes.title)}
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={cx(classes.truncateText, classes.description)}
            >
              <Html html={description} />
            </Typography>
            <div className={cx(classes.truncateText)}>
              <TagRow tags={episode.tags} />
            </div>
          </div>
          <EpisodeInfoRow episode={episode} className={classes.infoRow} />
        </div>
      </Paper>
    </Link>
  );
}

export function EmptyItem() {
  const classes = useStyles();
  return <div className={classes.root} />;
}

type EpisodeItemProps = {
  episode: Episode;
};

export default EpisodeItem;

import React from 'react';
import { useParams } from 'react-router-dom';
import CenterLoading from '../../../components/CenterLoading';
import NotFound from '../../../components/NotFound';
import { useGetEpisodeDetail } from '../../../gql/hooks/episode/episode';
import { useGetEpisodeComments } from '../../../gql/hooks/comment';
import EpisodeHeader from '../header/EpisodeHeader';
import EpisodeDetailContent from './Content';

function EpisodeDetail({ showId }: EpisodeDetailProps): JSX.Element {
  const { episodeId } = useParams<{ episodeId: string }>();

  const { loading, data } = useGetEpisodeDetail(episodeId);
  const {
    loading: commentsLoading,
    data: commentsData,
    hasMore,
    loadMore,
  } = useGetEpisodeComments(episodeId);

  if (loading) {
    return (
      <div>
        <CenterLoading />
      </div>
    );
  }

  const episode = data?.playerEpisodeFindOne;

  if (!episode || episode.show.id !== showId) {
    return <NotFound />;
  }

  return (
    <React.Fragment>
      <EpisodeHeader episode={episode} />
      <EpisodeDetailContent
        episode={episode}
        commentsLoading={commentsLoading}
        comments={commentsData?.playerCommentFind || []}
        hasMore={hasMore}
        loadMore={loadMore}
      />
    </React.Fragment>
  );
}

type EpisodeDetailProps = {
  showId: string;
};

export default EpisodeDetail;

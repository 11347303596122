import React from 'react';
import { useIntl } from 'react-intl';
import { Category } from '../../entities/show';

function CategoryText({ category }: CategoryTextProps): JSX.Element | null {
  const intl = useIntl();

  if (!category) {
    return null;
  }

  return (
    <React.Fragment>
      {intl.locale === 'zh' ? category.nameZh : category.nameEn}
    </React.Fragment>
  );
}

type CategoryTextProps = {
  category?: Category;
};

export default CategoryText;

import React from 'react';
import { Avatar, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ShowMembership } from '../../../entities/membership';
import { Show } from '../../../entities/show';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  imageContainer: {
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  image: {
    width: 180,
    height: 180,
  },
  subtitle: {
    fontWeight: 'normal',
    marginBottom: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(2),
  },
}));

function ShowInfo({ show, showMembership }: ShowInfoProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <Avatar
          variant="rounded"
          src={show.avatar || '#'}
          className={classes.image}
        />
      </div>
      <div>
        <Typography variant="h5" color="textPrimary">
          {show.name}
        </Typography>
        <Typography
          variant="h6"
          color="textPrimary"
          className={classes.subtitle}
        >
          {show.author}
        </Typography>
        <Typography color="textPrimary" className={classes.description}>
          {showMembership?.description}
        </Typography>
      </div>
    </div>
  );
}

type ShowInfoProps = {
  show: Show;
  showMembership: ShowMembership;
};

export default ShowInfo;

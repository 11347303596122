import React from 'react';
import { Helmet } from 'react-helmet-async';
import config from '../../../config';
import { Define } from '../../../define';
import { Show } from '../../../entities/show';

function HomeHeader({ show }: HomeHeaderProps): JSX.Element {
  const { id, name, author, avatar, intro, urlSlug, language, distributions } =
    show;

  let subdomain = urlSlug;
  if (urlSlug === 'firstorylab') {
    subdomain = 'lab';
  }

  const title = name;
  const description = intro + '\n Podcast powered by Firstory.';
  const lang = (language || 'ZH').toLowerCase();

  const applePodcast = distributions?.find(
    d => d.platformType === Define.Distribution.Platform.apple,
  );
  const applePodcastId = applePodcast ? applePodcast.platformId : null;

  const imageUrl = avatar;

  const websiteBaseUrl = `https://${subdomain}.firstory.io`;
  const baseUrl = websiteBaseUrl;

  const websiteUrl = websiteBaseUrl;

  const embedPlayerUrl = baseUrl + '/embed';
  const oembedUrl = `${baseUrl}/oembed?url=${encodeURIComponent(
    embedPlayerUrl,
  )}`;

  const canonicalUrl = websiteUrl;

  const schemaData: Record<string, unknown> = {
    '@context': 'https://schema.org',
    '@type': 'PodcastSeries',
    webFeed: `${config.rssUrlBase}/rss/user/${id}`,
    image: imageUrl,
    url: canonicalUrl,
    name: name,
    description,
    author: { '@type': 'Person', name: author || name },
    offers: [{ '@type': 'Offer', price: 'Free' }],
  };

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="music.song" />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:site_name " content={title} />
      <meta property="og:audio:type" content="audio/vnd.facebook.bridge" />
      <link rel="canonical" href={canonicalUrl} />
      <link
        rel="alternate"
        type="application/json+oembed"
        href={oembedUrl}
        title={title}
      />
      {applePodcastId && (
        <meta name="apple-itunes-app" content={`app-id=${applePodcastId}`} />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@firstorylab" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
  );
}

type HomeHeaderProps = {
  show: Show;
};

export default HomeHeader;

import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ShowMembership, TierStatus } from '../../../entities/membership';
import { Show } from '../../../entities/show';
import TierItem from './TierItem';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(4),
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
}));

function TierList({ show, showMembership }: TierListProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h5" color="textPrimary" className={classes.title}>
        <FormattedMessage id="donation.memberTier" />
      </Typography>
      <Grid container spacing={2}>
        {showMembership.subscriptionTiers
          .filter(t => t.status === TierStatus.active)
          .map(t => (
            <Grid key={t.id} item md={4} sm={12}>
              <TierItem show={show} tier={t} />
            </Grid>
          ))}
      </Grid>
    </div>
  );
}

type TierListProps = {
  show: Show;
  showMembership: ShowMembership;
};

export default TierList;

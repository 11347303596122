import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  Drawer,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Theme,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { imageproxy } from '../../../utils';
import { MessageId } from '../../../i18n';
import { Show } from '../../../entities/show';
import { ShowMembership } from '../../../entities/membership';
import useDisclosure from '../../../hooks/useDisclosure';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
  },
  image: {
    width: 45,
    height: 45,
    borderRadius: 5,
    objectFit: 'cover',
    marginRight: theme.spacing(2),
  },
  padding: {
    flexGrow: 1,
  },
  titleLink: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  toolbarButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  hideOnLarge: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  list: {
    width: 250,
  },
}));

type Item = {
  props: {
    component: React.ComponentType<any>;
    to: string;
  };
  text: MessageId;
};

function Navbar({ show, showMembership }: NavbarProps): JSX.Element {
  const intl = useIntl();
  const classes = useStyles();
  const drawer = useDisclosure();

  const title = show ? show.name : null;
  const imageUrl = show ? show.avatar : null;

  const items: Item[] = React.useMemo(() => {
    const items: Item[] = [
      {
        props: {
          component: Link,
          to: '/',
        },
        text: 'home',
      },
      {
        props: {
          component: Link,
          to: '/episodes',
        },
        text: 'website.allEpisodes',
      },
      {
        props: {
          component: Link,
          to: '/playlists',
        },
        text: 'website.playlists',
      },
      {
        props: {
          component: Link,
          to: '/voicemail',
        },
        text: 'website.voicemail',
      },
    ];

    if (
      showMembership?.subscriptionEnabled &&
      showMembership.subscriptionTiers.length
    ) {
      items.push({
        props: {
          component: Link,
          to: '/join',
        },
        text: 'website.join',
      });
    }

    return items;
  }, [showMembership]);

  return (
    <AppBar position="sticky" color="default" className={classes.appBar}>
      <Toolbar>
        <Link to="/" className={classes.titleLink}>
          {imageUrl && (
            <img
              src={imageproxy(imageUrl, 50)}
              alt={title || ''}
              className={classes.image}
            />
          )}
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        </Link>
        <div className={classes.padding} />
        {items.map(item => (
          <Button
            key={item.text}
            {...item.props}
            className={classes.toolbarButton}
          >
            {item.text && <FormattedMessage id={item.text} />}
          </Button>
        ))}
        <IconButton
          edge="start"
          className={classes.hideOnLarge}
          color="inherit"
          aria-label="menu"
          onClick={drawer.open}
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor="right" open={drawer.isOpen} onClose={drawer.close}>
          <List className={classes.list}>
            {items.map(item => (
              <ListItem button key={item.text} {...item.props}>
                <ListItemText primary={intl.formatMessage({ id: item.text })} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}

type NavbarProps = {
  show: Show;
  showMembership?: ShowMembership;
};

export default Navbar;

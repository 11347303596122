import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { MessageId } from '../../../i18n';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(4),
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
}));

const items: {
  title: MessageId;
  description: MessageId;
}[] = [
  {
    title: 'website.join.howto.choose.title',
    description: 'website.join.howto.choose.description',
  },
  {
    title: 'website.join.howto.pay.title',
    description: 'website.join.howto.pay.description',
  },
  {
    title: 'website.join.howto.link.title',
    description: 'website.join.howto.link.description',
  },
  {
    title: 'website.join.howto.listen.title',
    description: 'website.join.howto.listen.description',
  },
];

function HowTo(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h5" color="textPrimary" className={classes.title}>
        <FormattedMessage id="website.join.howto" />
      </Typography>
      <Grid container spacing={2}>
        {items.map(item => (
          <Grid key={item.title} item md={3} sm={12}>
            <Typography variant="h6" color="textPrimary">
              <FormattedMessage id={item.title} />
            </Typography>
            <Typography color="textPrimary">
              <FormattedMessage id={item.description} />
            </Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default HowTo;

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container, Typography, Theme, NoSsr } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Episode } from '../../../entities/episode';
import EpisodeInfoRow from '../../../components/EpisodeInfoRow';
import TagRow from '../../../components/TagRow';
import Controller from '../../../components/Controller';
import useAudio from '../../../components/Player/useAudio';
import ListenPlatformRow from '../../../components/ListenPlatformRow';
import Html from '../../../components/Html';
import CommentList from '../../../components/CommentList';
import { Comment } from '../../../entities/comment';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  titleContainer: {
    marginBottom: theme.spacing(4),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  playerContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 10,
    overflow: 'hidden',
  },
  image: {
    width: 400,
    height: 400,
    borderRadius: 5,
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: 200,
      height: 200,
    },
    [theme.breakpoints.down('xs')]: {
      width: 150,
      height: 150,
    },
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
}));

function EpisodeDetailContent({
  episode,
  comments,
  commentsLoading,
  hasMore,
  loadMore,
}: EpisodeDetailContentProps): JSX.Element {
  const classes = useStyles();
  const [slidingMs, setSlidingMs] = React.useState<number>(-1);

  const { isLoading, isPlaying, currentTimeMsRef, toggle, setCurrentTime } =
    useAudio({
      defaultUrl: episode.audioUrl,
    });

  const handleSliderAfterChange = React.useCallback(
    (value: number) => {
      setCurrentTime(value);
      setSlidingMs(-1);
    },
    [setCurrentTime],
  );

  return (
    <Container maxWidth="md" className={classes.container}>
      <div className={classes.titleContainer}>
        <Typography
          variant="h4"
          component="h1"
          color="textPrimary"
          className={classes.title}
        >
          {episode.title}
        </Typography>
        <EpisodeInfoRow episode={episode} />
        <TagRow tags={episode.tags} />
      </div>
      <div className={classes.playerContainer}>
        <Controller
          isEmbed
          defaultTitle={episode.title}
          defaultSubtitle={episode.show.name}
          defaultImageUrl={episode.imageUrl || episode.show.avatar}
          nowPlaying={episode}
          isLoading={isLoading}
          isPlaying={isPlaying}
          currentTimeMsRef={currentTimeMsRef}
          slidingMs={slidingMs}
          onPlayPauseClick={toggle}
          onSliderChange={setSlidingMs}
          onSliderAfterChange={handleSliderAfterChange}
        />
      </div>
      <Typography color="textPrimary" className={classes.sectionTitle}>
        <FormattedMessage id="podcast.distribution" />
      </Typography>
      <ListenPlatformRow
        showId={episode.show.id}
        distributions={episode.distributions}
      />
      <Typography
        variant="h5"
        component="h2"
        color="textPrimary"
        className={classes.sectionTitle}
      >
        <FormattedMessage id="general.description" />
      </Typography>
      <Html html={episode.description} />
      <Typography
        variant="h5"
        component="h2"
        color="textPrimary"
        className={classes.sectionTitle}
      >
        <FormattedMessage id="general.comment" />
      </Typography>
      <NoSsr>
        <CommentList
          loading={commentsLoading}
          record={episode}
          commentCount={episode.commentCount}
          comments={comments}
          hasMore={hasMore}
          loadMore={loadMore}
        />
      </NoSsr>
    </Container>
  );
}

type EpisodeDetailContentProps = {
  episode: Episode;
  comments: Comment[];
  commentsLoading: boolean;
  hasMore: boolean;
  loadMore: () => Promise<unknown>;
};

export default EpisodeDetailContent;

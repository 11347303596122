import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, Typography, Theme, Button } from '@material-ui/core';
import { Tier } from '../../../entities/membership';
import Html from '../../../components/Html';
import { sortPlans } from '../../Donation/Join/util';
import config from '../../../config';
import { Show } from '../../../entities/show';
import PlanItem from './PlanItem';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(4),
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
}));

function TierItem({ show, tier }: TierItemProps): JSX.Element | null {
  const classes = useStyles();

  const plans = sortPlans(tier.plans);

  const url = `${config.url}/join/${show.urlSlug || show.id}`;

  if (tier.plans.length === 0) {
    return null;
  }

  return (
    <Paper variant="outlined" className={classes.container}>
      <Typography variant="h6" className={classes.title}>
        {tier.title}
      </Typography>
      {tier.description && (
        <Html html={tier.description} className={classes.description} />
      )}
      <Grid container spacing={1}>
        {plans.map(p => (
          <Grid key={p.id} item xs={4}>
            <PlanItem plan={p} />
          </Grid>
        ))}
      </Grid>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        component="a"
        href={url}
        rel="noopener noreferrer"
      >
        <FormattedMessage id="website.join" />
      </Button>
    </Paper>
  );
}

type TierItemProps = {
  show: Show;
  tier: Tier;
};

export default TierItem;

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { Show } from '../../../entities/show';
import { ShowMembership } from '../../../entities/membership';
import Navbar from './Navbar';
import Footer from './Footer';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    width: '100%',
    overflow: 'auto',
  },
}));

function Layout({ show, showMembership, children }: LayoutProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Navbar show={show} showMembership={showMembership} />
      {children}
      <Footer />
    </div>
  );
}

type LayoutProps = {
  show: Show;
  showMembership?: ShowMembership;
  children: React.ReactChild;
};

export default Layout;

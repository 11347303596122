import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CenterLoading from '../../components/CenterLoading';
import LazyImage from '../../components/LazyImage';
import { imageproxy } from '../../utils';
import { useGetShowPlaylists } from '../../gql/hooks/playlist';
import AllPlaylistHeader from './header/AllPlaylistHeader';

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    height: 300,
  },
  container: {
    paddingTop: theme.spacing(8),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(8),
    textAlign: 'center',
  },
  item: {
    marginBottom: theme.spacing(4),
  },
  itemInfo: {
    display: 'flex',
  },
  image: {
    width: 150,
    height: 150,
    borderRadius: 5,
    backgroundColor: theme.palette.action.hover,
    [theme.breakpoints.down('xs')]: {
      width: 75,
      height: 75,
    },
  },
  right: {
    flex: 1,
    paddingLeft: theme.spacing(2),
  },
  playlistTitle: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  itemTitle: {
    color: theme.palette.text.primary,
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  recordCount: {
    display: 'block',
  },
  description: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  previewRecordList: {
    listStyle: 'none',
    margin: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: 0,
  },
  noPlaylist: {
    textAlign: 'center',
  },
}));

function PlaylistItem({ data, image }) {
  const classes = useStyles();
  const { title, description } = data;
  const previewImage = image;

  return (
    <div className={classes.item}>
      <div className={classes.itemInfo}>
        <div className={classes.left}>
          <Link to={`/playlists/${data.id}`}>
            {previewImage ? (
              <LazyImage
                src={imageproxy(previewImage, 500)}
                placeholder={imageproxy(previewImage, 50)}
                alt={data.title}
                className={classes.image}
              />
            ) : (
              <div className={classes.image} />
            )}
          </Link>
        </div>
        <div className={classes.right}>
          <Link to={`/playlists/${data.id}`}>
            <Typography
              variant="h5"
              component="h2"
              color="textPrimary"
              className={classes.playlistTitle}
            >
              {title}
            </Typography>
          </Link>
          <Typography color="textPrimary" className={classes.description}>
            {description}
          </Typography>
        </div>
      </div>
    </div>
  );
}

function AllPlaylist({ userId }) {
  const classes = useStyles();
  const { loading, data } = useGetShowPlaylists(userId);

  if (loading || !data) {
    return (
      <div className={classes.container}>
        <Typography variant="h4" component="h1" className={classes.title}>
          <FormattedMessage id="website.playlists" />
        </Typography>
        <div className={classes.loadingContainer}>
          <CenterLoading />
        </div>
      </div>
    );
  }

  return (
    <Container maxWidth="sm" className={classes.container}>
      <AllPlaylistHeader data={data} />
      <Typography
        variant="h4"
        component="h1"
        color="textPrimary"
        className={classes.title}
      >
        <FormattedMessage id="website.playlists" />
      </Typography>
      {data.playlists.length > 0 ? (
        data.playlists.map(p => (
          <PlaylistItem
            key={p.id}
            data={p}
            image={data.userInfo ? data.userInfo.avatar : null}
          />
        ))
      ) : (
        <Typography
          variant="h4"
          component="p"
          color="primary"
          className={classes.noPlaylist}
        >
          <FormattedMessage id="website.noPlaylists" />
        </Typography>
      )}
    </Container>
  );
}

export default AllPlaylist;

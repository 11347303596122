import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container, Theme } from '@material-ui/core';
import LoadMore from '../../components/LoadMore';
import { Episode } from '../../entities/episode';
import EpisodeItem from './EpisodeItem';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    marginBottom: theme.spacing(1),
  },
}));

function EpisodeList({
  episodes,
  hasMore,
  isFetchingAfter,
  loadMore,
}: EpisodeListProps): JSX.Element {
  const classes = useStyles();

  return (
    <Container maxWidth="md">
      {episodes.map(episode => (
        <div key={episode.id} className={classes.item}>
          <EpisodeItem episode={episode} />
        </div>
      ))}
      <LoadMore
        hasMore={hasMore}
        loading={isFetchingAfter}
        loadMore={loadMore}
      />
    </Container>
  );
}

type EpisodeListProps = {
  episodes: Episode[];
  hasMore?: boolean;
  isFetchingAfter?: boolean;
  loadMore?: () => Promise<unknown>;
};

export default EpisodeList;

import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Container, Button, Typography, Theme } from '@material-ui/core';
import Linkify from '../../components/Linkify';
import LazyImage from '../../components/LazyImage';
import { imageproxy } from '../../utils';
import { Show } from '../../entities/show';
import ListenPlatformRow from '../../components/ListenPlatformRow';
import ExternalLinkRow from '../../components/ExternalLinkRow';
import { useGetShowEpisode } from '../../gql/hooks/episode/episode';
import HomeHeader from './header/UserHeader';
import EpisodeList from './EpisodeList';
import CategoryText from './CategoryText';

const useStyles = makeStyles((theme: Theme) => ({
  loadingContainer: {
    height: 300,
  },
  container: {
    paddingTop: theme.spacing(8),
  },
  hero: {
    display: 'flex',
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  imageContainer: {
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  image: {
    width: 400,
    height: 400,
    borderRadius: 5,
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: 200,
      height: 200,
    },
    [theme.breakpoints.down('xs')]: {
      width: 150,
      height: 150,
    },
  },
  infoContainer: {
    flex: 1,
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  category: {},
  description: {
    whiteSpace: 'pre-line',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  listenPlatform: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  platformRow: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  platformRowIcons: {
    marginRight: theme.spacing(1),
  },
  donateButton: {
    margin: theme.spacing(1, 0),
  },
  recentEpisodeTitle: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  buttonContainer: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
}));

function Home({ show }: HomeProps): JSX.Element {
  const classes = useStyles();
  const { data } = useGetShowEpisode({ showId: show.id });

  const episodes = data?.playerEpisodeFind || [];

  const {
    name,
    author,
    avatar,
    intro,
    import: showImport,
    categories,
    distributions,
    externalLinks,
  } = show;

  return (
    <div className={classes.container}>
      <HomeHeader show={show} />
      <Container>
        <div className={classes.hero}>
          <div className={classes.imageContainer}>
            <LazyImage
              src={imageproxy(avatar, 500)}
              placeholder={imageproxy(avatar, 50)}
              alt={name}
              className={classes.image}
            />
          </div>
          <div className={classes.infoContainer}>
            <Typography
              color="textPrimary"
              variant="h4"
              component="h1"
              className={classes.title}
            >
              {name}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h5"
              component="h2"
              className={classes.subtitle}
            >
              {author}
            </Typography>
            <Typography color="textPrimary" className={classes.category}>
              <CategoryText category={categories[0]} />
            </Typography>
            <Typography color="textPrimary" className={classes.description}>
              <Linkify>{intro}</Linkify>
            </Typography>
            <Typography color="textPrimary" className={classes.listenPlatform}>
              <FormattedMessage id="podcast.distribution" />
            </Typography>
            {distributions?.length ? (
              <div className={classes.platformRow}>
                <div className={classes.platformRowIcons}>
                  <ListenPlatformRow
                    showId={show.id}
                    showImport={showImport}
                    distributions={distributions}
                  />
                </div>
              </div>
            ) : null}
            {externalLinks?.length ? (
              <React.Fragment>
                <Typography
                  color="textPrimary"
                  className={classes.listenPlatform}
                >
                  <FormattedMessage id="podcast.externalLinks" />
                </Typography>
                <ExternalLinkRow externalLinks={externalLinks} />
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </Container>
      <Typography
        variant="h5"
        component="h2"
        color="textPrimary"
        className={classes.recentEpisodeTitle}
      >
        <FormattedMessage id="website.latestEpisodes" />
      </Typography>
      <EpisodeList episodes={episodes} />
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to="/episodes"
        >
          <FormattedMessage id="general.viewAll" />
        </Button>
      </div>
    </div>
  );
}

type HomeProps = {
  show: Show;
};

export default Home;

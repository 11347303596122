import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Typography, Theme, Container } from '@material-ui/core';
import CenterLoading from '../../components/CenterLoading';
import { useGetShowEpisode } from '../../gql/hooks/episode/episode';
import { Show } from '../../entities/show';
import SearchBar from '../../components/SearchBar';
import EpisodeListHeader from './header/EpisodeListHeader';
import EpisodeList from './EpisodeList';

const useStyles = makeStyles((theme: Theme) => ({
  loadingContainer: {
    height: 300,
  },
  container: {
    paddingTop: theme.spacing(8),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(8),
    textAlign: 'center',
  },
  buttonContainer: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  searchContainer: {
    marginBottom: theme.spacing(2),
  },
}));

function AllEpisodeList({ show }: AllEpisodeListProps): JSX.Element {
  const classes = useStyles();

  const location = useLocation();
  const history = useHistory();

  const { tags } = useParams<{ tags?: string }>();
  const decodedTags = tags ? decodeURIComponent(tags) : null;

  const queryString = React.useMemo(() => {
    const q = new URLSearchParams(location.search);
    const queryString = q.get('query');
    return queryString?.trim() || '';
  }, [location]);

  const { loading, data, hasMore, isFetchingAfter, loadMore } =
    useGetShowEpisode({ showId: show.id, queryString });

  const handleQueryStringChange = React.useCallback(
    (q: string) => {
      const query = new URLSearchParams(location.search);
      query.set('query', q);
      history.push({
        ...location,
        search: query.toString(),
      });
    },
    [history, location],
  );

  const episodes = data?.playerEpisodeFind || [];

  return (
    <div className={classes.container}>
      <EpisodeListHeader show={show} tags={decodedTags} />
      <Typography variant="h4" component="h1" className={classes.title}>
        {decodedTags || <FormattedMessage id="website.allEpisodes" />}
      </Typography>
      <Container maxWidth="md" className={classes.searchContainer}>
        <SearchBar value={queryString} onChange={handleQueryStringChange} />
      </Container>
      {loading ? (
        <div className={classes.loadingContainer}>
          <CenterLoading />
        </div>
      ) : (
        <EpisodeList
          episodes={episodes}
          hasMore={hasMore}
          isFetchingAfter={isFetchingAfter}
          loadMore={loadMore}
        />
      )}
    </div>
  );
}

type AllEpisodeListProps = {
  show: Show;
};

export default AllEpisodeList;

import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import Episode from '../Episode';
import { Show as ShowPage } from '../Show';
import WebPlayerContext from '../WebPlayer/context';
import NotFound from '../../components/NotFound';
import CenterLoading from '../../components/CenterLoading';
import RedirectWithStatus from '../../components/RedirectWithStatus';
import useMainPlayer from '../../components/Player/useMainPlayer';
import { TrackerProvider } from '../../analytics/tracker';
import { useCustomTheme } from '../../theme';
import { useGetShowInfo } from '../../gql/hooks/show/show';
import { Show } from '../../entities/show';
import { useGetShowMembership } from '../../gql/hooks/membership/get-show-setting';
import { ShowMembership } from '../../entities/membership';
import TagList from './TagList';
import { VoiceMailBoxList, VoiceMailBoxDetail } from './VoiceMail';
import AllPlaylist from './AllPlaylist';
import PlaylistDetail from './PlaylistDetail';
import AllEpisodeList from './AllEpisodeList';
import EpisodeDetail from './EpisodeDetail';
import Home from './Home';
import Layout from './Layout';
import JoinMember from './Join';

function UserSiteRoutes({
  show,
  showMembership,
}: UserSiteRoutesProps): JSX.Element {
  const mainPlayerProps = useMainPlayer();
  const contextValue = React.useMemo(() => {
    return mainPlayerProps;
  }, [mainPlayerProps]);

  if (!show.urlSlug) {
    return <NotFound />;
  }

  return (
    <WebPlayerContext.Provider value={contextValue}>
      <Switch>
        <Route path="/embed/episodes/:episodeId">
          <Episode />
        </Route>
        <Route path="/embed">
          <ShowPage urlSlug={show.urlSlug} />
        </Route>
        <Route>
          <Layout show={show} showMembership={showMembership}>
            <Switch>
              <Route path="/" exact>
                <Home show={show} />
              </Route>
              <Route path="/playlists/:playlistId">
                <PlaylistDetail showId={show.id} />
              </Route>
              <Route path="/playlists">
                <AllPlaylist userId={show.id} />
              </Route>
              <Route path="/episodes/:episodeId">
                <EpisodeDetail showId={show.id} />
              </Route>
              <Route path="/episodes">
                <AllEpisodeList show={show} />
              </Route>
              <Route path="/tags/:tags">
                <AllEpisodeList show={show} />
              </Route>
              <Route path="/tags">
                <TagList />
              </Route>
              <Route path="/voicemail/:id">
                <VoiceMailBoxDetail showId={show.id} />
              </Route>
              <Route path="/voicemail">
                <VoiceMailBoxList user={show} />
              </Route>
              {showMembership?.subscriptionEnabled &&
              showMembership?.subscriptionTiers.length ? (
                <Route path="/join">
                  <JoinMember show={show} showMembership={showMembership} />
                </Route>
              ) : null}
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </Layout>
        </Route>
      </Switch>
    </WebPlayerContext.Provider>
  );
}

function UserSite({ showUrlSlug }: UserSiteProps): JSX.Element {
  const location = useLocation();
  const { loading, data } = useGetShowInfo(showUrlSlug);

  const show = data?.playerShowFindOneByUrlSlug;

  const { loading: membershipLoading, data: membershipData } =
    useGetShowMembership(show?.id);

  const websiteSettingActive = !!show?.websiteSetting?.active;
  const customTheme = useCustomTheme({ websiteSetting: show?.websiteSetting });

  if (loading || membershipLoading) {
    return <CenterLoading />;
  }

  if (!show || !websiteSettingActive || show.deleted) {
    return <NotFound />;
  }

  if (showUrlSlug === show.id) {
    const redirectUrl = `https://${show.urlSlug}.firstory.io${location.pathname}`;
    return <RedirectWithStatus isExternal to={redirectUrl} status={301} />;
  }

  return (
    <TrackerProvider
      userUrlSlug={show.urlSlug}
      userGaTrackingId={show?.websiteSetting?.gaTrackingId}
      userFbPixelId={show?.websiteSetting?.fbPixelId}
    >
      <ThemeProvider theme={customTheme}>
        <UserSiteRoutes show={show} showMembership={membershipData?.show} />
      </ThemeProvider>
    </TrackerProvider>
  );
}

type UserSiteProps = {
  showUrlSlug: string;
};

type UserSiteRoutesProps = {
  show: Show;
  showMembership?: ShowMembership;
};

export default UserSite;

import { useQuery } from '@apollo/client';
import { useGetShowInfoLegacy } from '../show';
import { GetShowPlaylists } from '../../v2/playlist';

export function useGetShowPlaylists(userId) {
  const { data: showData } = useGetShowInfoLegacy(userId);
  const { data, ...args } = useQuery(GetShowPlaylists, {
    variables: { showId: userId },
  });

  if (!data) {
    return args;
  }

  return {
    data: {
      ...showData,
      playlists: data && data.playerPlaylistFind ? data.playerPlaylistFind : [],
    },
    ...args,
  };
}

import React from 'react';
import { useIntl } from 'react-intl';
import { Paper, InputBase, IconButton, Theme } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  inputRow: {
    width: '100%',
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: theme.spacing(1),
  },
}));

function SearchBar({ value, onChange }: SearchBarProps): JSX.Element {
  const classes = useStyles();
  const intl = useIntl();
  const [qs, setQs] = React.useState<string>(value);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQs(e.target.value);
    },
    [],
  );

  const handleSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      onChange(qs);
    },
    [qs, onChange],
  );

  React.useEffect(() => {
    setQs(value);
  }, [value]);

  return (
    <div className={classes.root}>
      <Paper
        component="form"
        variant="outlined"
        className={classes.inputRow}
        onSubmit={handleSubmit}
      >
        <InputBase
          value={qs}
          className={classes.input}
          placeholder={intl.formatMessage({ id: 'general.search' })}
          onChange={handleChange}
        />
        <IconButton
          className={classes.iconButton}
          aria-label="search"
          type="submit"
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    </div>
  );
}

type SearchBarProps = {
  value: string;
  onChange: (value: string) => void;
};

export default SearchBar;

import React from 'react';
import { Container } from '@material-ui/core';
import { ShowMembership } from '../../../entities/membership';
import { Show } from '../../../entities/show';
import ShowInfo from './ShowInfo';
import TierList from './TierList';
import HowTo from './HowTo';
import Faq from './Faq';

function JoinMember({ show, showMembership }: JoinMemberProps): JSX.Element {
  return (
    <Container maxWidth="md">
      <ShowInfo show={show} showMembership={showMembership} />
      <TierList show={show} showMembership={showMembership} />
      <HowTo />
      <Faq />
    </Container>
  );
}

type JoinMemberProps = {
  show: Show;
  showMembership: ShowMembership;
};

export default JoinMember;

import { GetPlaylistDetailLegacy } from '../../v2/playlist';
import usePagination from '../../../hooks/usePagination';
import { transformArray, transformEpisode } from '../../transform';

export function useGetPlaylistDetailLegacy(playlistId) {
  const { data, ...args } = usePagination({
    dataKey: 'playerEpisodeFind',
    query: GetPlaylistDetailLegacy,
    variables: { playlistId },
    useSkip: true,
    ssr: true,
  });

  if (!data) {
    return args;
  }

  return {
    data: {
      playlist: data.playerPlaylistFindOne,
      feeds: transformArray(data.playerEpisodeFind, transformEpisode),
    },
    ...args,
  };
}
